import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslationService } from './services/translation.service';
import { locale as enLang } from './config/i18n/en';
import { locale as thLang } from './config/i18n/th';
import { NgxSpinnerService } from 'ngx-spinner';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { LayoutConfigService } from './services/layout-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'tutor';

  constructor(private translationService: TranslationService, private router: Router) {
    this.translationService.loadTranslations(enLang, thLang);
  }

  ngOnInit(): void {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      // if (event instanceof NavigationStart) {
      //   this.spinner.show();
      // }
      // if (event instanceof NavigationEnd) {
      //   setTimeout((_) => {
      //     this.spinner.hide();
      //   }, 500);
      // }
    });
  }

  ngOnDestroy(): void { }
}
