<div class="header-mobile">
  <div class="nav-bar">
    <img class="hamburger-menu" *ngIf="!isShowMenu" alt="menu-list" src="assets/images/icon/list.svg" (click)="toggleShowMenu()" />
    <img *ngIf="isShowMenu" class="list-close" alt="menu-list-close" src="assets/images/icon/x.svg" (click)="toggleShowMenu()" />
  </div>
  <div class="brand">
    <img src="./assets/images/icon/LOGO_EAZY_CAL_Final-01.png" class="logo" routerLink="/" />
    <!-- <img class="logo" [src]="host + config.logo_2x" routerLink="/" (click)="closeMenu()" /> -->
  </div>
  <!-- <div class="text-primary user-auth">ลงชื่อเข้าใช้</div> -->
  <app-user-auth [isMobile]="true"></app-user-auth>
</div>
<ng-container [ngTemplateOutlet]="menuNav"></ng-container>

<ng-template #menuNav>
  <div class="menu-nav" [ngClass]="isShowMenu ? 'show' : ''">
    <ng-container *ngFor="let menu of menuList$ | async; let i = index">
      <div class="menu-item" *ngIf="![1,3].includes(i)" [routerLink]="menu.disabled ? '' : menu.path" (click)="closeMenu()">
        <a>{{ menu.name | translate }}</a>
      </div>
      <ng-container *ngIf="i==1">
        <div class="menu-item" [ngClass]="{'icon-collapse': !isShowCourseCategory}">
          <div class="d-flex align-items-center gap-50" (click)="isShowCourseCategory = !isShowCourseCategory; isShowBlogCategory = false">
            <a>{{ menu.name | translate }}</a>
            <img class="dd-caret" src="./assets/images/icon/caret-down.svg" alt="caret" width="16">
          </div>
        </div>
        <div class="sub-menu" [ngClass]="{'collapse': !isShowCourseCategory}">
          <div class="collapse-box">
            <div class="collapse-space">
              <div class="sub-menu-box">
                <div class="menu-item" [routerLink]="'/courses'" (click)="closeMenu()">
                  {{ 'MENU.SEARCH_COURSE' | translate }}
                </div>
                <div class="menu-item" *ngFor="let item of course_categories" [routerLink]="['/courses/course-categories', item.id]" (click)="closeMenu()">
                  {{ item.name | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="i==3">
        <div class="menu-item" [ngClass]="{'icon-collapse': !isShowBlogCategory}">
          <div class="d-flex align-items-center gap-50" (click)="isShowBlogCategory = !isShowBlogCategory; isShowCourseCategory = false">
            <a>{{ menu.name | translate }}</a>
            <img class="dd-caret" src="./assets/images/icon/caret-down.svg" alt="caret" width="16">
          </div>
        </div>
        <div class="sub-menu" [ngClass]="{'collapse': !isShowBlogCategory}">
          <div class="collapse-box">
            <div class="collapse-space">
              <div class="sub-menu-box">
                <div class="menu-item" *ngFor="let item of Blog_Categories" (click)="sendSelectedItemAndNavigate(item.id)" (click)="closeMenu()">
                  {{ item.name | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div class="menu-item" routerLink="/cart" *ngIf="isAuthenticated" (click)="closeMenu()">
      <feather-icon name="shopping-cart"></feather-icon>
      <span *ngIf="cartQuery.getCartItem" class="cart-badge">{{ cartQuery.getCartItem }}</span>
      <span class="cart">{{ "LAYOUTS.HEADERS.HEADER.MOBILE.MY_CART" | translate }}</span>
    </div>

    <div class="menu-auth" *ngIf="user$ | async as user" (click)="closeMenu()">
      <ng-container *ngIf="user.role.id === RoleIds.STUDENT">
        <div class="menu-user" [routerLink]="'/students'">
          <feather-icon name="monitor" class="icon"></feather-icon>
          <span>ห้องเรียน</span>
        </div>
      </ng-container>

      <ng-container *ngIf="user.role.id === RoleIds.PARENT" (click)="closeMenu()">
        <div class="menu-user" [routerLink]="'/parents'">
          <feather-icon name="users" class="icon"></feather-icon>
          <span>ส่วนของผู้ปกครอง</span>
        </div>
      </ng-container>
    </div>

    <div class="menu-shared">
      <app-social-media></app-social-media>
    </div>

  </div>
</ng-template>