<div class="nav-menu">
  <div class="menu">
    <ul>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngFor="let menu of menuList$ | async; let i=index">
        <a [routerLink]="menu.path" *ngIf="![1,3].includes(i)">{{ menu.name | translate }}</a>
        <div class="dropdown dropdown-toggle" *ngIf="i==1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{ menu.name | translate }}
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a [routerLink]="'/courses'">{{ 'MENU.SEARCH_COURSE' | translate }}</a>
            <a *ngFor="let item of course_categories" [routerLink]="['/courses/course-categories', item.id]">{{ item.name }}</a>
          </div>
        </div>
        <div class="dropdown  dropdown-toggle" *ngIf="i==3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{ menu.name | translate }}
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a *ngFor="let item of Blog_Categories" (click)="sendSelectedItemAndNavigate(item.id)">{{ item.name }}</a>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div class="cart">
    <app-cart></app-cart>
  </div>

  <div class="social-media">
    <app-social-media></app-social-media>
  </div>

  <div class="ml-2 user-auth">
    <app-user-auth></app-user-auth>
  </div>