<div class="breadcrumb">
  <div
    class="breadcrumb-item"
    [ngClass]="breadcrumbConfig.length === i + 1 ? 'active' : ''"
    *ngFor="let breadcrumb of breadcrumbConfig; index as i"
  >
    <feather-icon *ngIf="i !== 0" name="chevron-right" class="icon"></feather-icon>
    <a [routerLink]="breadcrumbConfig.length === i + 1 ? [] : breadcrumb.route">{{ breadcrumb.title }}</a>
  </div>
</div>
